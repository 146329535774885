<div class="container m-b-medium">
  <div class="row">
    <div class="col-sm-6 text-holder">
      <h2>CONTACT</h2><br>
      <p>
        C/ Rio Guadiana, 52, 1B, 07830 Sant Josep de sa Talaia<br>
        <a href="mailto:olaf.stoelt@docdiamed.net">olaf.stoelt@docdiamed.net</a><br>
        <a href="tel:0034971808070">+34 971808070</a>
      </p>
    </div>
    <div class="col-sm-6 t-p b-p">
      <div #loaderWrapper class="loader-wrapper">
        <span #loader class="loader"><span #innerLoader class="inner-loader material-icons">{{loaderIcon}}</span></span>
      </div>
      <div #formWrapper>
        <h3>Get in touch with us!</h3>

        <form [formGroup]="contactForm" #form="ngForm" (ngSubmit)="form.valid && sendForm(form)">
          <mat-form-field appearance="outline" class="r-p" color="primary">
            <mat-label for="name">Name</mat-label>
            <input matInput type="text" id="name" formControlName="name">
          </mat-form-field>

          <mat-form-field appearance="outline" class="l-p">
            <mat-label for="mail">E-Mail</mat-label>
            <input matInput type="email" id="mail" formControlName="mail" required>
          </mat-form-field>

          <mat-form-field appearance="outline" class="full-width">
            <mat-label for="phone">Phone Number</mat-label>
            <input matInput type="tel" id="phone" formControlName="phone">
          </mat-form-field>

          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Message</mat-label>
            <textarea matInput rows="5" name="message" formControlName="message" required></textarea>
          </mat-form-field>

          <button mat-raised-button type="submit" name="submit" color="primary">Send</button>
        </form>

      </div>
    </div>
  </div>
</div>
