import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {

  companyName: string  = "Doc Dia Med";
  companySlogan: string = "Ihre Anfrage ist unser Anliegen.";
  constructor() { }

  ngOnInit(): void {
  }

}
