<header class="nav-component">
  <nav class="container">
    <div class="row">
      <div class="logo-holder col-sm-12">
        <img src="./assets/images/logo.png">
      </div>
      <!--
      <div class="btn-holder col-sm-8">
        <button mat-button>Expectations</button>
        <button mat-button>Contact</button>
      </div>
      !-->
    </div>
  </nav>
</header>
