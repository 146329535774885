import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {tryCatch} from "rxjs/internal-compatibility";

@Injectable()
export class MessageService {

  constructor(private _http: HttpClient) {
  }

  sendMessage(body) {
    return this._http.post('https://sendmail-server.web.app/sendmail', body);
  }
}
