import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MessageService} from "../../services/message.service";
import {FormControl, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})

export class ContactFormComponent implements OnInit {

  contactForm = new FormGroup({
    name: new FormControl(''),
    mail: new FormControl(''),
    phone: new FormControl(''),
    message: new FormControl('')
  });

  // Icon inside the loader, uses material-icon
  loaderIcon: string


  @ViewChild('loaderWrapper') loaderWrapper: ElementRef;
  @ViewChild('loader') loader: ElementRef;
  @ViewChild('innerLoader') innerLoader: ElementRef;
  @ViewChild('formWrapper') formWrapper: ElementRef;


  constructor(public _MessageService: MessageService) {
  }

  ngOnInit(): void {
  }


  sendForm(form: any) {

    console.log(form);
    //TODO Start loading animation
    this.loaderWrapper.nativeElement.classList.add('active');
    this.loader.nativeElement.classList.add('active');
    this.formWrapper.nativeElement.classList.add('disable');

    this._MessageService.sendMessage(form.value).subscribe(
      () => this.animateLoader(form, "success"),
      error => this.animateLoader(form, "error")  // TODO do something useful with error message ^^
    );

  }

  private animateLoader(form, status) {
    if (status == "error") {
      this.loader.nativeElement.classList.remove('active'); // Loader stops spinning
      this.innerLoader.nativeElement.classList.add('active'); // Loader Icon shows up
      this.loaderIcon = "error_outline"; //

    } else if ("success") {
      this.contactForm.reset()
      form.resetForm();
    }

    setTimeout(() => {
      this.loaderWrapper.nativeElement.classList.remove('active');
      this.loader.nativeElement.classList.remove('active');
      this.formWrapper.nativeElement.classList.remove('disable');
      this.loaderIcon = "";

    }, 5000);

  }
}
