<div class="image-text">
  <div class="container">
    <div class="row">
      <div class="col-md-4">
        <div class="image" [ngStyle]="{'background-image': 'url(./assets/images/skitze.jpg)'}"></div>
        <div class="text-holder">
          <h2>DEVELOPMENTS</h2>
          <h3>always sustainable</h3>
          <p>
            We put as much as possible on renewable energy.<br><br>
            We develop us with every project and continue our journey and increase renewable resources.<br><br>
            Modern, timeless design that cosiness and warmth mediated.<br><br>
            Cooperation with local companies.<br><br>
            Maintenance, pool service and garden maintenance for the first year included in the purchase price.
          </p>
        </div>
      </div>
      <div class="col-md-4">
        <div class="image" [ngStyle]="{'background-image': 'url(./assets/images/team.jpg)'}"></div>
        <div class="text-holder">
          <h2>SOLUTIONS</h2>
          <h3>satisfaction guaranteed</h3>
          <p>
            We as a team overcome walls and other obstacles in the way for optimal solutions and<br><br>
            in cooperation with the best specialist in every field and<br><br>
            with imagination and curiosity, open for innovations and suggestions from our customers.
          </p>
        </div>
      </div>
      <div class="col-md-4">
        <div class="image" [ngStyle]="{'background-image': 'url(./assets/images/SantaPonsa_Project/10.jpg)'}"></div>
        <div class="text-holder">
          <h2>INVESTMENTS</h2>
          <h3>our investment can be yours too</h3>
          <p>
            We perform our projects primarily with own funds, private investors and only then with banks.<br><br>
            Through good planning, a small mobile team and based on many years of experience, we implement projects
            faster than others.<br><br>
            This is the basis for offering attractive conditions to our investors.<br><br>
            Become part of our TEAM!
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
