import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NavComponent} from './nav/nav.component';
import {MatButtonModule} from '@angular/material/button';
import {ImageTextComponent} from './image-text/image-text.component';
import {FullscreenImageComponent} from './fullscreen-image/fullscreen-image.component';
import {ContactFormComponent} from './contact-form/contact-form.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ParallaxComponent} from './parallax/parallax.component';
import {ProjektGalleryComponent} from './projekt-gallery/projekt-gallery.component';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {MessageService} from "../services/message.service";
import { ProjectsComponent } from './projects/projects.component';

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    ImageTextComponent,
    FullscreenImageComponent,
    ContactFormComponent,
    ParallaxComponent,
    ProjektGalleryComponent,
    ProjectsComponent
  ],
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MatButtonModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
    ],
  providers: [MessageService],
  bootstrap: [AppComponent]
})
export class AppModule {
}
